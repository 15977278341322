/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, useHistory } from "react-router-dom";
import { MediaEpisodeDetailForm } from "./MediaEpisodeDetailForm";
import MediaEpisodeSegmentEditForm from "../Segment/MediaEpisodeSegmentEditForm";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import * as API from '../../framework/API/api';
import { ACCESS_LEVEL, ASSETTYPE, ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MEDIACATEGORIES, MODULE, OTTROUTETYPE, PLATFORMTYPE, PUBLISHING_SOURCE } from "../../framework/constant/constant";
import MediaEpisodePublishingEditForm from "./MediaEpisodePublishingEditForm";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { EditPageHeader } from "../../components/EditPageHeader";
import MediaEpisodeMataDataEditForm from "./MediaEpisodeMataDataEditForm";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmAlert, ConfirmDeleteAlert } from "../../ConfirmAlert";
import MediaEpisodeAssetEditForm from "./MediaEpisodeAssetEditForm";
import moment from "moment";
import IsShortMediaCollectionSelection from "../../framework/forms/IsShortMediaCollectionSelection";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import SaveButton from "../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { utility } from "../../framework/utility/utilityProvider";
import { DataHelper } from "../../framework/helper/DataHelper";
import FileAttachmentForm from "./FileAttachmentForm";
import MarkerEditForm from "./MarkerEditForm";
import BossDialog from "../../components/BossDialog";
import { MediaTemplateEditForm } from "./MediaTemplateEditForm";
import { GenreEditForm } from "../masters/GenreEditForm";

export const MediaEpisodeEditForm = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { SID } = useParams();
  const user = utility.getValue(LOCALSTORAGE_KEY.userData);
  const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);

  let blankDataItem = {
    SID: SID,
    Content: {},
    Genres: [],
    SubGenres: [],
    Description: "",
    Title: props?.Title ?? '',
    EpisodeTitle: "",
    AssetId: "",
    EpisodeNumber: 1,
    IsLive: false,
    ReferenceCode: '',
    IsMusic: false,
    MusicCategory: {}
  }

  const [mediaEpisodeEntity, setMediaEpisodeEntity] = useState(blankDataItem);
  const [mediaEpisodeMetaData, setMediaEpisodeMetaData] = useState({
    PGRating: {},
    CensorRating: {},
    MediaTechnicalPrototype: {},
    Languages: [],
    Keywords: '',
    ProductionYear: "",
    Synopsis: [],
    CastAndCrew: [],
    MamID: '',
    AudioTrack: [],
    dynamicField: {},
    CensorshipDetail: []
  });
  const [mediaEpisodeSegments, setMediaEpisodeSegments] = useState([]);
  const [deletedSegments, setDeletedSegments] = useState([]);
  const [mediaEpisodePublishings, setMediaEpisodePublishings] = useState([]);
  const [tabNumber, setTabNumber] = React.useState("1");
  const [IMDbData, setIMDbData] = useState({});
  const [selectedMusicCategory, setSelectedMusicCategory] = useState({});

  const isAssestID = utility.isNumeric(SID) == false;
  const isEdit = isAssestID || SID > 0 ? true : false;

  const [disableSave, setDisableSave] = useState(isEdit ? false : true);
  const [showOttAssetEditForm, setShowOttAssetEditForm] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const [dialogMessage, setDialogMessagge] = useState([]);
  const [mediaEpisodeDescription, setMediaEpisodeDescription] = useState("");
  const [openFormatSelectionCollection, setOpenFormatSelectionCollection] = useState(false);
  const [selectedFormats, setSelectedFormats] = useState([]);
  const [mediaCategory, setMediaCategory] = useState([]);
  const [removedMedia, setRemovedMedia] = useState([]);
  const [assetDataItem, setAssetDataItem] = useState({});

  const [mediaOpen, setMediaOpen] = useState(false);
  const [selectedAttachedMedia, setSelectedAttachedMedia] = useState([]);
  const [showMetaDataLocalization, setShowMetaDataLocalization] = useState(false);
  const [localizationData, setLocalizationData] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);
  const [markerData, setMarkerData] = useState([]);

  const [openPublishingDialog, setOpenPublishingDialog] = useState(false);
  const [defaultDealRight, setDefaultDealRight] = useState({});
  const [publishingDialogTitle, setPublishingDialogTitle] = useState(null);
  const [showMarkerEditForm, setShowMarkerEditForm] = useState(false);
  const [mediaTeplateOpen, setMediaTeplateOpen] = useState(false);

  const blankLocalizationStore = {
    SID: 0,
    Language: {},
    Genres: [],
    SubGenres: [],
    Title: '',
    Description: '',
    MetaData: {
      PGRating: {},
      CensorRating: {},
      MediaTechnicalPrototype: {},
      Languages: [],
      Keywords: '',
      ProductionYear: "",
      Synopsis: [],
      CastAndCrew: [],
      AudioTrack: [],
      dynamicField: {},
      CensorshipDetail: [],
      Categories: [],
    },
    MediaEpisode_id: ''
  }

  const [localizationStoreData, setLocalizationStoreData] = useState(blankLocalizationStore);
  //LIVE EVENTS
  const [liveEventDetails, setLiveEventDetails] = useState([]);
  const [isSaveMetaData, setIsSaveMetaData] = useState(false);
  const [mediaTemplateSelectionOpen, setMediaTemplateSelectionOpen] = useState(false);
  const [genres, setGenres] = useState([]);
  const [filterGenres, setFilterGenres] = useState([]);
  const [showGenreForm, setShowGenreForm] = useState(false);

  const lang = useTranslation();

  useEffect(() => {
    loadCombo();
  }, [localizationStoreData?.Language])

  useEffect(() => {
    if (isEdit) {
      loadMediaEpisode();
    }
    setMediaEpisode();
  }, [])

  const loadCombo = async () => {
    let languagesRes = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
    let filteredLanguages = languagesRes.data.filter((x) => x.Description !== company.Language.Description);
    setLanguages(filteredLanguages);

    let genreRes = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
    setGenres(genreRes.data);

    if (localizationStoreData.Language && Object.keys(localizationStoreData.Language).length > 0) {
      let filterGenreData = genreRes.data.filter((item) => item?.Language?._id == localizationStoreData.Language?._id);
      setFilterGenres(filterGenreData);
    } else {
      setFilterGenres(genreRes.data);
    }
  }

  const onChangeLocalization = (e) => {
    if (e.target.name == 'Language') {
      let filterGenreData = genres.filter((item) => item?.Language?._id == e.target.value?._id);
      setFilterGenres(filterGenreData);
      setLocalizationStoreData({ ...localizationStoreData, Language: e.target.value, Genres: [], SubGenres: [], MetaData: blankLocalizationStore.MetaData });
    } else {
      setLocalizationStoreData({ ...localizationStoreData, [e.target.name]: e.target.value });
    }
  }

  const setMediaEpisode = async () => {
    let mediaCategoryData = await API.getDataLookup(ENTITYNAME.MediaCategory);
    setMediaCategory(mediaCategoryData.data);
    let mediaCategory = mediaCategoryData.data.find((item) => item.SID == props.mediaCategory);
    setMediaEpisodeDescription(mediaCategory.Description);
  }

  const handleFormatList = (dataList) => {
    let newData = utility.checkDuplicateInData(dataList, selectedFormats, 'Name')
    setSelectedFormats([...selectedFormats, ...newData]);
  }

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  //todo : when meta data add is clicked then the metadata close and version opens
  const handleExpand = (bool) => {
    console.log("in");
  }

  const handleMetaData = (data) => {

    let metaData = { ...data }
    if (isEdit && mediaEpisodeMetaData.hasOwnProperty('_id')) {
      metaData = { _id: mediaEpisodeMetaData._id, ...data }
    }
    setIsSaveMetaData(true);
    setMediaEpisodeMetaData(metaData);
  }

  const handleDeletedSegments = (segmentObject) => {
    console.log(segmentObject);
    setDeletedSegments(() => [...deletedSegments, segmentObject]);
  }

  const handleSegmentData = (data) => {
    console.log(data);
    setMediaEpisodeSegments(data);
  }

  const handleMediaPublishingData = (data) => {
    setMediaEpisodePublishings(data);
    setPublishingDialogTitle(null);
  }

  const handleRemovePublishingData = (removedDataItem) => {
    setRemovedMedia(old => [...old, removedDataItem]);
  }

  const loadMediaEpisode = async () => {

    let res = { message: "Not Valid", success: false };
    if (isAssestID) {
      res = await API.getEntityfromAssestID(ENTITYNAME.MediaEpisode, SID);
    } else {
      res = await API.getEntity(ENTITYNAME.MediaEpisode, parseInt(SID));
      if (!res.data) {
        res = await API.getEntityfromAssestID(ENTITYNAME.MediaEpisode, SID);
      }
    }
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    setMediaEpisodeEntity({ ...blankDataItem, ...res.data });

    //FECTH FORMATS
    if (res.data?.Formats && res.data?.Formats.length > 0) {

      let resFormatData = await API.getData(ENTITYNAME.Format, { query: ['_id', 'in', res.data?.Formats] });
      let formats = resFormatData.success && resFormatData.data && resFormatData.data.length > 0 ? resFormatData.data : [];

      setSelectedFormats(formats ?? []);
    }

    // FECTH LOCALIZATION
    let resLocalizationData = await API.getData(ENTITYNAME.MediaEpisodeLocalization, { query: ['MediaEpisode_id', 'in', [res.data?._id]] });
    let localization = resLocalizationData.success && resLocalizationData.data && resLocalizationData.data.length > 0 ? resLocalizationData.data : [];
    setLocalizationData(localization ?? []);

    setMarkerData(res.data.Marker);
    //load asset details
    if (res.data.IsOttAsset) {
      let assetRes = await API.getData(ENTITYNAME.OttAsset, { query: [['Source._id', '=', res.data._id], ['Source.entityName', '=', 'mediaepisode']] });
      if (assetRes.data && assetRes.data.length > 0) {
        setAssetDataItem({ ...assetRes.data[0], IsOttAsset: res.data.IsOttAsset });
        setShowMarkerEditForm(true);
      }
    }
    //load live event details
    let liveEventDetails = await API.getData(ENTITYNAME.MediaEpisodeDetailLiveEvent, { query: ['MediaEpisode_id', '=', res.data._id.toString()] });
    setLiveEventDetails(liveEventDetails.data);
    if (liveEventDetails.data && liveEventDetails.data.length > 0) {
      handleTabChange({}, "8")
    }

    //load attached media
    let attachedMedia = await API.getData(ENTITYNAME.MediaEpisode, { query: ['Media._id', '=', res.data._id.toString()] });
    setSelectedAttachedMedia(attachedMedia.data);

    // load file Attachment 
    let fileAttachment = await API.getData(ENTITYNAME.FileAttachments, { query: ['MediaEpisode_id', '=', res.data._id.toString()] });
    setAttachmentData(fileAttachment.data);

    let mediaDealRights = await API.getData(ENTITYNAME.MediaDealRights, {
      query: ["media_id", "=", res.data._id.toString()],
    });

    let tempData = [];
    if (mediaDealRights.data.length > 0) {
      tempData = mediaDealRights.data.map((obj, index) => {
        return {
          ...obj,
          index: index
        }
      })
    } else {
      tempData = mediaDealRights.data
    }
    setMediaEpisodePublishings(tempData ?? []);
    setSelectedMusicCategory(res?.data?.MusicCategory ?? {})


  }

  const handleSetMediaEpisodeEntity = async (data, IMDbData) => {

    let vodType = await API.getData(ENTITYNAME.VODType, { query: ['Name', '=', 'SVOD'] });
    let ottPosterType = await API.getData(ENTITYNAME.OttPosterType, { query: ['Description', '=', 'Portrait'] });
    let videoType = await API.getData(ENTITYNAME.OttVideoType, { query: ['Description', '=', 'HLS'] });
    let provider = await API.getData(ENTITYNAME.OttProvider, { query: ['IsDefault', '=', true] });
    let prepareAssetsData = {
      ...assetDataItem,
      Title: data?.Title,
      IsOttAsset: true,
      VodTypes: vodType?.data[0],
      routeType: OTTROUTETYPE.NoRoute,
      Provider: provider?.data[0]?._id,
      Posters: [
        {
          Description: data?.Title,
          OttPosterType: ottPosterType?.data[0],
          Url: data?.ImageUrl
        }
      ],
      Videos: [
        {
          Description: "N/A",
          OttVideoType: videoType?.data[0],
          Url: "N/A"
        }
      ]
    }
    setAssetDataItem(prepareAssetsData);
    setMediaEpisodeMetaData({ ...IMDbData });
    setMediaEpisodeEntity(data);
  }

  const onFormatDelete = (dataItem) => {
    console.log("delete format started");
    setSelectedFormats((old) =>
      old.filter((item) => dataItem.SID !== item.SID)
    );
    console.log("delete platform end");
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  const MyFormatDeleteCommandCell = (props) => (
    <div className="flex-container martb">
      <div><DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => onFormatDelete(props.dataItem), () => { })} /></div>
    </div>
  )

  const handleSetDisableSave = (value) => {
    setDisableSave(value);
  }

  const saveMetaData = async (data) => {

    let mediaEpisodeMetaFinalData = {
      ...mediaEpisodeMetaData,
      Synopsis: mediaEpisodeMetaData?.Synopsis?.map(x => {
        return {
          ...x,
          Language: {
            _id: x.Language._id,
            ISOCode: x.Language.ISOCode,
            GoogleApiCode: x.Language.GoogleApiCode,
            Description: x.Language.Description
          },
        }
      })
    }

    let metaDataSaveData = DataHelper.saveMetaDataEntity(mediaEpisodeMetaFinalData, data)
    let responseMetaData = await API.saveData(ENTITYNAME.MediaEpisodeMetaData, {
      ...metaDataSaveData,
      MediaEpisodeSID: data.SID,
      MediaEpisode_id: data._id
    });

    if (!responseMetaData.success) {
      toast.error(responseMetaData.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    setMediaEpisodeMetaData({
      PGRating: {},
      CensorRating: {},
      MediaTechnicalPrototype: {},
      Languages: [],
      Keywords: '',
      ProductionYear: "",
      Synopsis: [],
      AudioTrack: [],
      dynamicField: {},
      CensorshipDetail: []
    });
  }

  const saveSegments = async (entity) => {

    // updating planning for media episode, if segment is created after doing planning without segment
    if (selectedFormats && selectedFormats.length > 0) {
      let formatsSegmentResponse = await API.createSegmentsBasedOnFormatsAttached(selectedFormats, mediaEpisodeEntity.SID);
      console.log(formatsSegmentResponse)
    }

    if (!SID || parseInt(SID) > 0) return;

    // Deleting Segments
    if (deletedSegments && deletedSegments.length > 0) {
      deletedSegments.map((item) => {
        console.log(item);
        API.deleteData(ENTITYNAME.MediaEpisodeSegment, item.SID);
      });
      console.log(deletedSegments);
      console.log("deletedSegments");
    }


    // Saving Segments
    mediaEpisodeSegments.map((item) => {
      API.saveData(ENTITYNAME.MediaEpisodeSegment, { ...item, MediaEpisodeSID: entity.SID });
    });

    // if formats are attached we need to make sure segment of same format type is created in system
    if (mediaEpisodeEntity._id && mediaEpisodeSegments.length > 0) {
      let updateResponse = await API.updateSegmentInPlanning(mediaEpisodeEntity._id);
      console.log(updateResponse)
    }

  }

  const saveLogsFunction = (media_id, res, resMediaDealRights, assetsData) => {

    let inEdit = mediaEpisodeEntity.SID != 0;

    let logData = {
      data: {
        ...res.data, MediaDealRights: resMediaDealRights?.data, mediaEpisodeSegments: mediaEpisodeSegments, ...mediaEpisodeMetaData, _id: media_id, AttchMedia: selectedAttachedMedia, Assets: assetsData
      },
      message: res.message
    }

    // Log Capture
    if (props.mediaCategory == MEDIACATEGORIES.Program) {
      logData = {
        ...logData,
        event: inEdit ? LOGEVENT.UPDATE_PROGRAM : LOGEVENT.CREATE_PROGRAM,
        module: MODULE.MEDIA_EPISODE_PROGRAM,
      };
    } else if (props.mediaCategory == MEDIACATEGORIES.Movie) {
      logData = {
        ...logData,
        event: inEdit ? LOGEVENT.UPDATE_EPISODE : LOGEVENT.CREATE_EPISODE,
        module: MODULE.MEDIA_EPISODE_MOVIE,
      };
    } else {
      logData = {
        ...logData,
        event: inEdit ? LOGEVENT.UPDATE_TRAILER : LOGEVENT.CREATE_TRAILER,
        module: MODULE.MEDIA_EPISODE_TRAILER
      };
    }
    API.SaveLogs(logData);

  }

  //SAVE AND UPDATE DEFAULT MEDIA DEAL RIGHTS
  const setDefaultDealRights = async () => {

    setPublishingDialogTitle("Please create deal rights first")
    handleTabChange({}, 3);
    setOpenPublishingDialog(true);

    //FETCH DEFAULT DEAL RIGHTS
    let resDefaultDealRights = await API.getData(ENTITYNAME.UserDefaultDealRights, { query: [["user_id", "=", user._id]] });
    if (resDefaultDealRights.success && resDefaultDealRights.data.length > 0) {

      setDefaultDealRight({
        ...resDefaultDealRights?.data[0],
        Publishing: {
          ...resDefaultDealRights?.data[0].Publishing,
          PublishingSource: PUBLISHING_SOURCE.MediaEpisode
        }
      });
    } else {
      return;
    }
  }

  const updateDefaultDealRights = async () => {

    let newDefaultDealRight = mediaEpisodePublishings.find((x) => x.IsDefault);

    //FETCH DEFAULT DEAL RIGHTS
    if (newDefaultDealRight) {

      delete newDefaultDealRight.IsDefault;

      let updateData = {
        user_id: user._id,
        Publishing: newDefaultDealRight
      }

      if (Object.keys(defaultDealRight).length > 0) updateData._id = defaultDealRight._id;

      await API.saveData(ENTITYNAME.UserDefaultDealRights, updateData);

    }

  }

  const onSave = async () => {

    let localmediaEpisodeEntity = { ...mediaEpisodeEntity };

    const media_id = localmediaEpisodeEntity._id;

    if (localmediaEpisodeEntity.SID == 0) {
      const updatedContent = await API.getAndSaveNextNumber(
        localmediaEpisodeEntity.Content.SID,
        { ...localmediaEpisodeEntity.Content, isSaveNextnumber: true }
      );

      if (!updatedContent.success) {
        toast.error(updatedContent.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }

      localmediaEpisodeEntity.AssetId = updatedContent.data.HouseNumber;
    }

    console.log(markerData)
    let saveData = {
      ...localmediaEpisodeEntity,
      IMDbID: Object.keys(IMDbData).length > 0 ? IMDbData?.imdbID : "",
      IsMusic: localmediaEpisodeEntity.IsMusic ?? false,
      MusicCategory: localmediaEpisodeEntity.IsMusic ? { _id: selectedMusicCategory?._id, SID: selectedMusicCategory?.SID, Name: selectedMusicCategory?.Name } : {},
      ReferenceCode: localmediaEpisodeEntity.ReferenceCode ?? localmediaEpisodeEntity.AssetId,
      Formats: selectedFormats && selectedFormats.length > 0 ? selectedFormats.map(a => a?._id) : [],
      IsOttAsset: assetDataItem.IsOttAsset ?? false,
      MaterialID: mediaEpisodeSegments.some(x => x?.IsDefault) ? mediaEpisodeSegments.find(x => x?.IsDefault)?.SegmentNumber : "",
      Marker: markerData ?? []
    }

    let data = DataHelper.saveMediaEpisodeEntity(saveData, mediaEpisodeEntity.MediaCategory, assetDataItem.IsOttAsset);


    if (data?._id && data?._id.toString().length > 0) {
      delete data._id
    }

    if (assetDataItem.IsOttAsset && !isValidAsset()) {
      return;
    }

    //PUT MEDIA RIGHTS VALIDATION HERE
    if (user && user?.Channels?.length > 1 && mediaEpisodePublishings?.filter(x => x?.PlatformType?.SID == PLATFORMTYPE[0].SID)?.length == 0) {
      await setDefaultDealRights();
      return;
    }

    if (!isEdit) {
      await updateDefaultDealRights();
    }

    let res = await API.saveData(ENTITYNAME.MediaEpisode, data);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    //UPDATE EPISODE COUNT IN SEASON IF MEDIA CATEGPRY EPISODE
    if (res.data.MediaCategory.SID == MEDIACATEGORIES.Episode) {
      await API.updateSeasonEpisodeCount({
        data: res.data, //episode saved entity
        entityName: ENTITYNAME.MediaEpisode
      });
    }

    //save Attach media
    await saveMediaInIsShorts();

    //PUBLISHING TAB SAVE OR CREATING DEAFAULT rights
    let resMediaDealRights = await API.updateMediaDealRights(
      res.data,
      removedMedia,
      mediaEpisodePublishings.filter(x => x.PublishingSource == PUBLISHING_SOURCE.MediaEpisode),
      PUBLISHING_SOURCE.MediaEpisode,
      mediaEpisodePublishings.length == 0
    );

    //META DATA TAB SAVE
    if (isSaveMetaData) {
      await saveMetaData(res.data);
    }

    //VERSION TAB SAVE
    await saveSegments(res.data)

    //OTT ASSET TAB SAVE
    let assetsData = {};
    if (assetDataItem.IsOttAsset) {
      let assetRes = await onAssetSubmit({ ...res.data, _id: media_id });
      if (assetRes.success) {
        assetsData = assetRes.data
      }
    }

    // Log Capture
    saveLogsFunction(media_id, res, resMediaDealRights, assetsData);

    toast.success("Saved Successfully", {
      position: toast.POSITION.TOP_RIGHT
    });

    setMediaEpisodeEntity(res.data);

    if (props.quickEdit) {
      navigate(location.state.prevPath);
    } else {
      let findIndex = location?.state?.prevPath.indexOf("?");
      let pagination = location?.state?.prevPath?.slice(findIndex, location?.state?.prevPath?.length) ?? "";
      let state = {
        quickEdit: false,
        prevPath: location?.state?.prevPath,
        pagination: location?.state.pagination ?? pagination,
        paths: location.state.paths
      }
      navigate(
        `${location.pathname.slice(0, location.pathname.lastIndexOf("/"))}/${res.data.SID}`,
        { state: state }
      );
    }

  }

  //asset
  const setAssetEntity = (entity) => {
    setAssetDataItem(entity);
  }

  function isValidAsset() {

    if (!assetDataItem.VodTypes || Object.keys(assetDataItem.VodTypes).length == 0) {
      toast.error(`${lang.please_select_vod_type_validation_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if ((assetDataItem.routeType == (OTTROUTETYPE.Page || OTTROUTETYPE.PiPage) || assetDataItem.routeType.ID == (OTTROUTETYPE.Page || OTTROUTETYPE.PiPage)) && (assetDataItem.page_id == "" || assetDataItem.page_id == undefined)) {
      toast.error(`${lang.please_select_page}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if ((assetDataItem.routeType == OTTROUTETYPE.Player || assetDataItem.routeType.ID == OTTROUTETYPE.Player)
      && assetDataItem.Videos.length == 0) {
      toast.error(`${lang.please_attach_atleast_one_video_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if ((assetDataItem.routeType == OTTROUTETYPE.Deeplink || assetDataItem.routeType.Description == 'Deeplink') && !assetDataItem.Videos.some(x => x.OttVideoType.Description == "Deeplink")) {
      toast.error(`${lang.please_add_deeplink_in_video_list_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (assetDataItem.Videos.length == 0) {
      toast.error(`${lang.please_attach_atleast_one_video_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (assetDataItem.Posters.length == 0) {
      toast.error(`${lang.please_attach_atleast_one_poster_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }

  const onAssetSubmit = async (mediaEpisodeEntity) => {

    const saveData = {
      Title: mediaEpisodeEntity.Title,
      Description: mediaEpisodeEntity.Title,
      TBA: assetDataItem.TBA ?? false,
      StartDate: assetDataItem.StartDate ? new Date(assetDataItem.StartDate).getTime() : new Date(moment(new Date()).format('YYYY-MM-DD')).getTime(),
      EndDate: assetDataItem.EndDate ? new Date(assetDataItem.EndDate).getTime() : new Date(moment(new Date()).add(1, 'M').format('YYYY-MM-DD')).getTime(),
      OttAssetTypeSID: ASSETTYPE['Media Asset'],
      VodTypes: {
        _id: assetDataItem.VodTypes._id,
        SID: assetDataItem.VodTypes.SID,
        Name: assetDataItem.VodTypes.Name
      },
      Provider: assetDataItem.Provider ? (typeof assetDataItem.Provider == "string") ? assetDataItem.Provider : assetDataItem.Provider?._id : "",
      Archive: assetDataItem.Archive ?? false,
      Source: { _id: mediaEpisodeEntity._id, SID: mediaEpisodeEntity.SID, Title: mediaEpisodeEntity.Title, MediaCategory: mediaEpisodeEntity.MediaCategory, entityName: 'mediaepisode' },
      routeType: typeof assetDataItem.routeType === 'object' ? assetDataItem.routeType.ID : assetDataItem.routeType,
      page_id: typeof assetDataItem.page_id === 'object' ? assetDataItem.page_id._id : assetDataItem.page_id,
      Videos: assetDataItem.Videos ?? [],
      Posters: assetDataItem.Posters ?? [],
      RentalRetailPrice: assetDataItem.VodTypes.Name == 'TVOD' ? assetDataItem.RentalRetailPrice : 0,
      RentalWholesalePrice: assetDataItem.VodTypes.Name == 'TVOD' ? assetDataItem.RentalWholesalePrice : 0,
      WebAssets: assetDataItem?.WebAssets ? assetDataItem?.WebAssets?._id : null,
      accessLevel: assetDataItem?.accessLevel?._id ?? ACCESS_LEVEL.Both,
      Segments: (assetDataItem?.Segments && assetDataItem?.Segments?.length > 0) ? assetDataItem?.Segments?.map((x) => {
        return {
          _id: x?._id,
          Description: x?.Description
        }
      }) : []
    }

    if (assetDataItem._id != undefined) {
      saveData._id = assetDataItem._id;
      saveData.SID = assetDataItem.SID
    }
    console.log(saveData);

    let res = await API.saveData(ENTITYNAME.OttAsset, saveData);
    let logData = { event: res.message == "inserted" ? LOGEVENT.CREATE_ASSETS : LOGEVENT.UPDATE_ASSETS, module: MODULE.OTT_ASSETS, data: res.data, message: res.message };
    API.SaveLogs(logData);
    return res;
  };

  const openMedia = (e) => {
    if (Object.keys(selectedMedia).length > 1) {
      navigate(`/home/MediaEpisode/${selectedMedia.MediaCategory.Description}/${selectedMedia.SID}`, { state: { copy: false } })
    } else {
      console.log('Select Media');
      toast.info(`Select Media`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    e.preventDefault();
  }

  const handleMediaSelect = (dataList) => {

    let duplicate = [];
    let toAdd = [];

    if (dataList.length > 0) {
      dataList.map((x) => {
        if (selectedAttachedMedia.some(y => y.SID == x.SID)) {
          duplicate.push(x.Title);
        } else {
          toAdd.push(x);
        }
      })
    }

    if (duplicate.length > 0) {
      toast.info(`${duplicate.join(',')} are already exists`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    setSelectedAttachedMedia([...selectedAttachedMedia, ...toAdd]);
  }

  //call when save button clicks
  const saveMediaInIsShorts = async () => {
    console.log(selectedAttachedMedia)
    if (selectedAttachedMedia.length == 0) return;

    for (const element of selectedAttachedMedia) {
      let item = element;

      let attachedMedia = {
        _id: mediaEpisodeEntity._id,
        SID: mediaEpisodeEntity.SID,
        Title: mediaEpisodeEntity.Title,
        MediaCategory: mediaEpisodeEntity.MediaCategory,
        entityName: 'mediaepisode'
      }

      let response = await API.saveData(ENTITYNAME.MediaEpisode, { _id: item._id, Media: attachedMedia });

      if (!response.success) {
        console.log(response)
      }

    }

  }

  const MyAttachMediaCommandCell = (props) => (
    <div style={{ display: "flex" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteAttachedMedia(props.dataItem), () => { })} />
    </div>
  )

  const deleteAttachedMedia = async (dataItem) => {
    let filterMedia = selectedAttachedMedia.filter(x => x._id != dataItem._id);
    setSelectedAttachedMedia(filterMedia);

    let response = await API.saveData(ENTITYNAME.MediaEpisode, { _id: dataItem._id, Media: {} });
    if (!response.success) {
      console.log(response)
    }
  }

  const handleMetaDataLocaliztaion = async (data) => {
    let dataStore = {
      _id: localizationStoreData._id,
      SID: localizationStoreData.SID,
      MediaEpisode_id: localizationStoreData.MediaEpisode_id,
      Language: {
        _id: localizationStoreData.Language._id,
        SID: localizationStoreData.Language.SID,
        Description: localizationStoreData.Language.Description
      },
      MetaData: data,
      Title: localizationStoreData?.Title,
      Description: localizationStoreData?.Description,
      Genres: localizationStoreData?.Genres?.length > 0 ? localizationStoreData?.Genres?.map((x) => ({
        _id: x?._id,
        Description: x?.Description
      })) : [],
      SubGenres: localizationStoreData?.SubGenres?.length > 0 ? localizationStoreData?.SubGenres?.map((x) => ({
        _id: x?._id,
        Description: x?.Description
      })) : [],
    }
    setLocalizationStoreData(dataStore);
  }

  const onSaveLocalizationData = async () => {
    if (!localizationStoreData?.Language || Object.keys(localizationStoreData?.Language).length == 0) {
      toast.error(lang.please_select_language_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    // Check for duplicates in `localizationData`
    const isDuplicate = localizationData.some((item) => (item?._id != localizationStoreData?._id && item?.Language?._id == localizationStoreData?.Language?._id));
    if (isDuplicate) {
      toast.error(localizationStoreData?.Language?.Description + ` ${lang.already_exists_global_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let finalData = DataHelper.saveMediaEpisodeLocalizationEntity(localizationStoreData, mediaEpisodeEntity);
    let res = await API.saveData(ENTITYNAME.MediaEpisodeLocalization, finalData);
    if (!res.success) {
      toast.error(responseMetaData.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if (res.message === 'inserted') {
      setLocalizationData([...localizationData, res.data]);
    } else {
      let updateLocalizationData = localizationData.map((x) => {
        if (x._id == res.data._id) {
          return res.data;
        } else {
          return x
        }
      });
      setLocalizationData(updateLocalizationData);
    }

    setShowMetaDataLocalization(false);
    setLocalizationStoreData(blankLocalizationStore);
  };

  const removeLocalization = async (dataItem) => {
    let filterGenres = localizationData.filter(x => x._id != dataItem._id);
    setLocalizationData(filterGenres);
    await API.deleteData(ENTITYNAME.MediaEpisodeLocalization, dataItem._id, "_id");
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const editLocalizationGridData = async (dataItem) => {
    setShowMetaDataLocalization(true);
    setLocalizationStoreData(dataItem);
  }

  const CommandCellLocalization = (props) => {
    return (
      <div className="flex-container martb">
        <EditRoundButton onClick={() => editLocalizationGridData(props.dataItem)} />
        <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeLocalization(props.dataItem), () => { })} />
      </div>
    );
  }

  const onCancelLocalization = () => {
    setLocalizationStoreData(blankLocalizationStore);
    setShowMetaDataLocalization(!showMetaDataLocalization);
  }

  const onCancel = () => {

    //OLD
    // () => history.length > 1 ? navigate(-1) : window.close()

    console.log(mediaEpisodeEntity);
    console.log(location.state)

    if (mediaEpisodeEntity.SID == 0) {

      ConfirmAlert(() => {
        if (location.state && location.state.prevPath) {
          navigate(location.state.prevPath)
        } else {
          navigate(-1) ? navigate(-1) : window.close();
        }
      }, () => { return; }, "Discard changes?", "Are you sure you want to discard changes?")
    } else {
      if (location.state && location.state.prevPath) {
        let state = {
          ...location.state,
          pagination: location.state.pagination ?? "",
          prevPath: location?.state?.prevPath,
          paths: location.state.paths.slice(0, -1)
        }
        navigate(location.state.paths.length == 1 ? location.state.paths.pop() + location.state.pagination ?? "" : location.state.paths.pop(), { state: state })
      } else {
        navigate(-1) ? navigate(-1) : window.close();
      }
    }
  }

  const onSetSelectedMediaEpisode = async (dataList) => {
    setIsSaveMetaData(true);
    setMediaEpisodeMetaData({ ...mediaEpisodeMetaData, ...dataList[0].Value.metaData });
  }

  return (<>

    <EditPageHeader title={mediaEpisodeDescription + " -> " + (mediaEpisodeEntity ? mediaEpisodeEntity.Title : `${lang.new_button_dialog_header}`)} disableSave={disableSave} onSubmit={onSave} onCancel={onCancel} showMediaTemplate={true} onSaveAndCreateMediaTemplate={() => setMediaTeplateOpen(true)} onSelectMediaTemplate={() => setMediaTemplateSelectionOpen(true)} defaultLanguage={company.Language.Description ?? ""} />

    <MediaEpisodeDetailForm dataItem={mediaEpisodeEntity} setDataItem={setMediaEpisodeEntity} mediaCategory={props.mediaCategory} mediaCategoryType={props.mediaCategoryType} setMediaEpisodeData={handleSetMediaEpisodeEntity} setSaveState={handleSetDisableSave} quickEdit={location?.state?.quickEdit ?? false} IMDbData={IMDbData} setIMDbData={setIMDbData} selectedMusicCategory={selectedMusicCategory} setSelectedMusicCategory={setSelectedMusicCategory} setMediaEpisodeMetaData={handleMetaData} onSave={onSave} />

    <div className='row'>
      <div className='col-12'> <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabNumber}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              {liveEventDetails.length > 0 && <Tab label={lang.live_event_details} value="8" />}
              <Tab label={lang.meta_data_label} value="1" />
              <Tab label={lang.version_label} value="2" />
              <Tab label={lang.publishing_label} value="3" />
              <Tab label={lang.ott_assets_collection_header} value="4" />
              <Tab label={lang.format_label} value="5" />
              <Tab label={lang.attach_media_label} value="6" />
              {mediaEpisodeEntity.SID > 0 && <Tab label={lang.media_episode_localization} value="7" />}
              <Tab label={lang.attachments_label} value="9" />
              {showMarkerEditForm && <Tab label={lang.marker_label} value="10" />}
            </TabList>
          </Box>
          <TabPanel value={"1"}>
            <MediaEpisodeMataDataEditForm language={company.Language ?? {}} mediaData={mediaEpisodeEntity} SID={mediaEpisodeEntity.SID} metaData={mediaEpisodeMetaData} getMetaData={handleMetaData} expand={handleExpand} />
          </TabPanel>
          <TabPanel value={"2"}>
            <MediaEpisodeSegmentEditForm SID={mediaEpisodeEntity.SID} segmentData={mediaEpisodeSegments} getSegmentData={handleSegmentData} mediaEpisodeEntity={mediaEpisodeEntity} audioTrack={mediaEpisodeMetaData.AudioTrack} handleDeletedSegments={handleDeletedSegments} />
          </TabPanel>
          <TabPanel value={"3"}>
            <MediaEpisodePublishingEditForm mediaCategory={mediaCategory.find((obj) => obj.SID == props.mediaCategory) ?? { isLong: true }} mediaEpisodeEntity={mediaEpisodeEntity} publishingSource={PUBLISHING_SOURCE.MediaEpisode} data={mediaEpisodePublishings} handlePublishings={handleMediaPublishingData} handleRemovePublishingData={handleRemovePublishingData} openPublishingDialog={openPublishingDialog} setOpenPublishingDialog={setOpenPublishingDialog} defaultDealRight={defaultDealRight} isNewMedia={!isEdit} dialogtTitle={publishingDialogTitle} />
          </TabPanel>
          <TabPanel value={"4"}>
            <div className="row" style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "30px 20px 30px 10px" }}>
              <MediaEpisodeAssetEditForm source={'mediaepisode'} sourceEntity={mediaEpisodeEntity} entityname={ENTITYNAME.OttAsset} setDataEntity={setAssetEntity} dataItem={assetDataItem} />
            </div>
          </TabPanel>
          <TabPanel value={"5"}>
            <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "10px 20px 20px 20px" }}>
              <AddRoundButton style={{ marginBottom: '5px' }} onClick={() => setOpenFormatSelectionCollection(true)} />
              <Grid data={selectedFormats} style={{ height: "30vh" }}>
                <GridColumn cell={MyFormatDeleteCommandCell} width={"40px"} locked={true} />
                <GridColumn field="Name" title={lang.name_column} editable={false} />
                <GridColumn field="SegmentType.Description" title={lang.segment_type_column} editable={false} />
              </Grid>
              {openFormatSelectionCollection && <CollectionSelection entityname={ENTITYNAME.Format} title={lang.select_format_collection} wherestatement={['SID', '>', 0]} closeForm={() => setOpenFormatSelectionCollection(!openFormatSelectionCollection)} setDataList={handleFormatList} width={"50vw"} />}
            </div>
          </TabPanel>
          <TabPanel value={"6"}>
            <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "10px 20px 20px 20px" }}>
              <RoundButton style={{ marginBottom: '5px' }} icon={'link'} title={lang.attach_media_button_tooltip} onClick={() => setMediaOpen(true)} />
              <Grid data={selectedAttachedMedia} style={{ height: "30vh" }}>
                <GridColumn cell={MyAttachMediaCommandCell} width={"40px"} locked={true} />
                <GridColumn field="AssetId" title={lang.assetId_column} editable={false} />
                <GridColumn field="Title" title={lang.title_column} editable={false} />
              </Grid>
            </div>
          </TabPanel>
          {mediaEpisodeEntity.SID > 0 && <TabPanel value={"7"}>
            <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "10px 20px 20px 20px" }}>
              <AddRoundButton style={{ marginBottom: '5px' }} onClick={() => setShowMetaDataLocalization(true)} />
              <Grid data={localizationData}
                style={{ height: "30vh" }}>
                <GridColumn cell={CommandCellLocalization} width={"70px"} />
                <GridColumn field="Title" title={lang.title_label} />
                <GridColumn field="Description" title={lang.description_label} />
                <GridColumn field="Language.Description" title={lang.language_column} />
              </Grid>
            </div>
          </TabPanel>}
          {liveEventDetails.length > 0 && <TabPanel value={"8"}>
            <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "10px 20px 20px 20px" }}>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.team_a_label}</label>
                      <input className="pl-2" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"TeamA"} onChange={() => { }} value={liveEventDetails[0].TeamA} disabled={true} />
                    </div>
                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.team_b_label}</label>
                      <input className="pl-2" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"TeamB"} onChange={() => { }} value={liveEventDetails[0].TeamB} disabled={true} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.type_label} </label>
                      <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={[]}
                        name="Type"
                        textField="Description"
                        dataItemKey="SID"
                        value={liveEventDetails[0].Type}
                        onChange={() => { }}
                        validator={(value) => value ? "" : "Please select the value"}
                        disabled={true}
                      />
                    </div>
                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.round_label} </label>
                      <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={[]}
                        name="Round"
                        textField="Description"
                        dataItemKey="SID"
                        value={liveEventDetails[0].Round}
                        onChange={() => { }}
                        validator={(value) => value ? "" : "Please select the value"}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 form-group">
                      <label htmlFor="TabView">{lang.start_date_label} </label>
                      <input type="date" className="form-control form-control-sm" name="StartDate" value={moment(new Date(liveEventDetails[0].StartDateTime)).format('YYYY-MM-DD')} onChange={() => { }} disabled={true} />
                    </div>
                    <div className="col-3">
                      <label htmlFor="">{lang.start_time_label} </label>
                      <TimePickerWithFormat className="form-control form-control-sm" name="StartTime" value={moment(new Date(liveEventDetails[0].StartDateTime)).utc().format('hh:mm:ss:SS')} onChange={() => { }} disabled={true} />
                    </div>
                    <div className="col-6">
                      <label htmlFor="">{lang.duration_label} </label>
                      <TimePickerWithFormat className="form-control form-control-sm" name="Duration" value={utility.convertMilisecondsToStringWithFrames(liveEventDetails[0].Duration)} onChange={() => { }} disabled={true} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="">{`${lang.team_a_poster_url_label} (Optional)`}</label>
                      <input
                        name={"TeamAPosterUrl"}
                        className="form-control form-control-sm"
                        type={'text'}
                        value={liveEventDetails[0].TeamAPosterUrl}
                        onChange={() => { }}
                        disabled={true}
                      />
                    </div>
                    <div className="col-6 form-group">
                      <label htmlFor="">{`${lang.team_b_poster_url_label} (Optional)`}</label>
                      <input
                        name={"TeamBPosterUrl"}
                        className="form-control form-control-sm"
                        type={'text'}
                        value={liveEventDetails[0].TeamBPosterUrl}
                        onChange={() => { }}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{`Venue (Optional)`}</label>
                      <input className="pl-2" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Venue"} onChange={() => { }} value={liveEventDetails[0].Venue} disabled={true} />
                    </div>
                    <div className="col-6">
                      <label htmlFor="">{`Poster Url (Optional)`}</label>
                      <input
                        name={"PosterUrl"}
                        className="form-control form-control-sm"
                        type={'text'}
                        value={liveEventDetails[0].PosterUrl}
                        onChange={() => { }}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>}
          <TabPanel value="9">
            <FileAttachmentForm MediaEpisode_id={mediaEpisodeEntity._id} setAttachmentData={setAttachmentData} attachmentData={attachmentData} />
          </TabPanel>
          {showMarkerEditForm &&
            <TabPanel value="10">
              <MarkerEditForm MediaEpisode_id={mediaEpisodeEntity._id} setMarkerData={setMarkerData} markerData={markerData} />
            </TabPanel>}
        </TabContext>
      </Box>
      </div>
      {showMetaDataLocalization && <BossDialog
        title={lang.localization_dialog_header}
        onClose={onCancelLocalization}
        width={"950px"}
        // height={"700px"}
      >
        <div className="row">
          <div className="col-6">
            <SaveButton onClick={onSaveLocalizationData} ></SaveButton>
            <CancelButton onClick={onCancelLocalization} ></CancelButton>
          </div>
          <div className="col-1 mt-1">
            <label>{lang.language_label}:</label>
          </div>
          <div className="col">
            <DropDownList
              data={languages}
              textField="Description"
              dataItemKey="_id"
              value={localizationStoreData.Language}
              onChange={onChangeLocalization}
              name={"Language"}
            />
          </div>
        </div>
        <div className="mt-3">
          <div className="row">
            <div className="col-12">
              <label htmlFor="">{lang.title_label}</label>
              <input type="text" className="form-control form-control-sm" name="Title" value={localizationStoreData.Title} onChange={onChangeLocalization} required />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <label htmlFor="">{lang.description_label}</label>
              <input rows={3} type="text" className="form-control form-control-sm" name="Description" value={localizationStoreData.Description} onChange={onChangeLocalization} required />
            </div>
          </div>
          <div className="row mb-3 mt-2">
            <div className="col-5">
              <label htmlFor="TabView">{lang.genre_label}</label>
              <MultiSelect
                data={filterGenres}
                name={"Genres"}
                textField="Description"
                dataItemKey="_id"
                value={localizationStoreData.Genres}
                onChange={onChangeLocalization} />
            </div>
            <div className="col-1" style={{ padding: '0px' }}>
              <button type="button" title={lang.create_genre_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                style={{ height: "30px", margin: '20px 0px 0px 0px' }} onClick={() => { setShowGenreForm(true) }}><i className="fa-solid fa-plus fa-xs"></i>
              </button>
            </div>
            <div className="col-5">
              <label htmlFor="TabView">{lang.sub_genre_label} </label>
              <MultiSelect
                data={filterGenres}
                name={"SubGenres"}
                textField="Description"
                dataItemKey="_id"
                value={localizationStoreData.SubGenres}
                onChange={onChangeLocalization} />
            </div>
            <div className="col-1" style={{ padding: '0px' }}>
              <button type="button" title={lang.create_sub_genre_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                style={{ height: "30px", margin: '20px 0px 0px 0px' }} onClick={() => { setShowGenreForm(true) }}><i className="fa-solid fa-plus fa-xs"></i>
              </button>
            </div>
          </div>
        </div>
        <MediaEpisodeMataDataEditForm language={localizationStoreData?.Language ?? {}} metaData={localizationStoreData.MetaData} getMetaData={handleMetaDataLocaliztaion} />
      </BossDialog>
      }
      {isDialog && <MandatoryFeildsBox message={dialogMessage} onClose={dialogClose} />}
      {showOttAssetEditForm && <CustomEditForm
        {...props} //to get other props from customEditForm
        cancelEdit={(setShowOttAssetEditForm(false))}
        // onSubmit={handleSubmit} //ERROR
        onSubmit={() => { }} //ERROR
      //  item={editItem}
      />}
      {mediaOpen && <IsShortMediaCollectionSelection addButtonTitle={lang.select_button_text} title={lang.media_library_dialog_header} setDataList={handleMediaSelect} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setMediaOpen(false)} width={"50vw"} />}
      {mediaTeplateOpen && <BossDialog
        title={lang.media_template_dialog_header}
        onClose={() => setMediaTeplateOpen(false)}
        width={"950px"}>
        <MediaTemplateEditForm mediaData={mediaEpisodeEntity} SID={mediaEpisodeEntity.SID} metaData={mediaEpisodeMetaData} getMetaData={handleMetaData} expand={handleExpand}></MediaTemplateEditForm></BossDialog>}
      {mediaTemplateSelectionOpen && <CollectionSelection addButtonTitle={lang.select_button_text} title={lang.select_media_template_collection_header} entityname={ENTITYNAME.MediaTemplate} wherestatement={['addBy._id', '=', utility.getValue(LOCALSTORAGE_KEY.user_id)]} closeForm={() => setMediaTemplateSelectionOpen(!mediaTemplateSelectionOpen)} setDataList={(dataList) => onSetSelectedMediaEpisode(dataList)} mode={'single'} width={"50vw"} />}
      {showGenreForm && <GenreEditForm item={{ Language: localizationStoreData?.Language }} cancelEdit={() => setShowGenreForm(false)} refresh={() => loadCombo()} />}
    </div>
  </>);
}
