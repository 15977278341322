/* eslint-disable */
import React, { useState, useEffect, memo, useRef } from 'react'
import { Grid, GridColumn as Column, GridNoRecords, getSelectedState } from "@progress/kendo-react-grid";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import * as API from "../../framework/API/api";
import { DateOnlyCell } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { ENTITYNAME, MILLISECONDSINADAY } from '../../framework/constant/constant';
import BossDialog from '../../components/BossDialog';
import { getter } from '@progress/kendo-data-query';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import ContractCollectionSelection from '../Contract/ContractCollectionSelection';
import { ConfirmAlert } from '../../ConfirmAlert';

const ShortFormMediaEpisodeHistory = memo(({ selectedData, closeForm, showTitle }) => {

    const SELECTED_FIELD = "selected";
    const DATA_ITEM_KEY = "_id";
    const idGetter = getter(DATA_ITEM_KEY);

    const lang = useTranslation();
    const defaultDates = utility.getFirstAndLastDateOfMonth();
    const blankDataItem = { FromDate: defaultDates.FirstDate, ToDate: defaultDates.LastDate, Channel: {}, MediaCategory: selectedData.MediaCategory };
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [channel, setChannel] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(lang.no_record_found_error_message);
    const [mediaOpen, setMediaOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState({ Title: "" });
    const payloadRef = useRef({});

    // lang.data_loading_error_message

    let data = Array.isArray(selectedData) ? selectedData : [selectedData];

    const [selectedState, setSelectedState] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
        console.log(channelRes.data)
        setDataItem({ ...blankDataItem, Channel: [channelRes.data[0]] })
    }

    console.log(data);

    const onChange = async (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    };

    const isValidforExport = () => {
        if (!utility.isValidDate(dataItem.FromDate)) {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!utility.isValidDate(dataItem.ToDate)) {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ToDate < dataItem.FromDate) {
            toast.error(`${lang.please_select_to_date_grater_then_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true
    }
    const loadHistory = async () => {

        setLoadingStatus(lang.data_loading_error_message);

        if (isValidforExport()) {
            let fromdate = utility.convertStringDatetoMilliseconds(dataItem.FromDate);
            let todate = utility.convertStringDatetoMilliseconds(dataItem.ToDate) + MILLISECONDSINADAY - 1000;
            payloadRef.current = {
                startdate: fromdate,
                enddate: todate,
                channelsid: dataItem.Channel.map((x) => { return x.SID }),
                mediacategorytypesid: [selectedData.MediaCategoryType.SID],
                isExport: false,
                media_id: selectedData._id
            }
            loadData();
        }

    }

    const loadData = async () => {
        console.log(payloadRef.current);

        var res = await API.getInterstitialHistoryReport(payloadRef.current);

        console.log(res)
        if (res.success) {
            if (res.data.length == 0) {
                setLoadingStatus(lang.no_record_found_error_message);
                setGridData([]);
                setFilterData([]);
                return;
            } else {

                setGridData(() => res.data.map(data => {
                    return {
                        ChannelName: (data.Channel?.FullChannelName ?? data.Channel?.name ?? ""),
                        ...data
                    }
                }))
                setFilterData(() => res.data.map(data => {
                    return {
                        ChannelName: (data.Channel?.FullChannelName ?? data.Channel?.name ?? ""),
                        ...data
                    }
                }))

            }

        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const handleMediaSelect = (dataList) => {
        setSelectedMedia(dataList[0]);
    }

    const deleteSelectedMedia = () => {
        setSelectedMedia({ Title: "" });
    }

    const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
        let selectedIds = Object.keys(newSelectedState);
        let tempSelected = [];
        if (checked) {
            selectedIds.map((value) => {
                if (newSelectedState[value]) {
                    let filtered = gridData.find((obj) => obj._id == value);
                    tempSelected.push(filtered);
                }
            });
        }
        setSelectedItems(tempSelected);
    };

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);
        let selectedIds = Object.keys(newSelectedState);
        let tempSelected = [];
        selectedIds.map((value) => {
            if (newSelectedState[value]) {
                let filter = gridData.find((obj) => obj._id == value);
                tempSelected.push(filter);
            }
        });
        setSelectedItems(tempSelected);
    };

    const updateInterstitial = async () => {

        let newMedia = selectedMedia;
        let notValid = [];

        //FOR LOOP OF SELECTED SLOTS FOR UPDATING MEDIA
        for (let i = 0; i < selectedItems.length; i++) {
            const item = selectedItems[i];
            let slotDateTime = item.Date;

            //CHECK VALID FOR SLOT OR NOT

            let isValid = (newMedia.Publishings == undefined || newMedia.Publishings.length == 0) || 
            (newMedia.Publishings.length > 0 && newMedia.Publishings.some((x) => 
            x.PublishStartDate + (x?.PublishStartDateTime ?? 0) <= slotDateTime && 
            x.PublishEndDate + (x?.PublishEndDateTime ?? 0) >= slotDateTime && 
            x.Channel.some((y) => y.FullChannelName == item.ChannelName)))

            if (isValid) {
    
                let res = await API.saveData(ENTITYNAME.ProgramSchedule, {
                    _id: item._id,
                    MediaEpisode_id: selectedMedia._id,
                    TcIn: selectedMedia.TcIn,
                    TcOut: selectedMedia.TcOut,
                });

            } else {
                notValid.push(item);
            }

        }
        
        setSelectedMedia({ Title: "" });
        setSelectedState({});
        setSelectedItems([]);

        toast.error(notValid.length > 0 ? 
            `${notValid.length} slot(s) are failed to update, these slots are not valid for selected media!!` : 
            `Successfully updated !!`, 
            { position: toast.POSITION.TOP_RIGHT}
        )
        loadData();
    }

    const onReplace = async () => {

        if (!selectedMedia || Object.keys(selectedMedia).length < 2) {
            toast.error(`Please select media first !!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (selectedMedia._id == selectedData._id) {
            toast.error(`Please select different media !!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let currentDate = utility.convertStringDatetoMilliseconds(utility.convertMilisecondsToDateString(new Date().getTime()));
        if (selectedItems.some((x) => x.Date <= currentDate + (MILLISECONDSINADAY - 1000))) {
            toast.error(`Please select scheduled items of future dates !!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        //COMPARE SELECTED DATA & SELECTED MEDIA DURATION
        let msg = selectedData.Duration != selectedMedia.Duration ?
            `Duration of selected media is not same as ${selectedData.Title}, This may cause underrun / overrun !!` :
            `Changing ${selectedData.Title} to ${selectedMedia.Title}`;

        let msg2 = selectedData.Duration != selectedMedia.Duration ? "Do you still want to proceed ?" : "";

        ConfirmAlert(
            () => updateInterstitial(),
            () => { },
            lang.confirm_button_text,
            msg,
            '',
            msg2
        );

    }

    return (
        <BossDialog
            title={`Schedule ${lang.history_dialog_header} - ${selectedData?.Title}`}
            onClose={closeForm}
            width={"50%"}
        >
            <div className="row">
                <div className="col-3" style={{ padding: "0px 0px 0px 16px" }}>
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.from_date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"FromDate"} value={dataItem.FromDate} onChange={onChange} />
                    </div>
                </div>
                <div className="col-3" style={{ padding: "0px 0px 0px 16px" }}>
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.to_date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"ToDate"} value={dataItem.ToDate} onChange={onChange} min={dataItem.FromDate} />
                    </div>
                </div>
                <div className="col-3 form-group" style={{ padding: "0px 0px 0px 16px" }}>
                    <label htmlFor="TabView">{lang.channel_label} *</label>
                    <MultiSelect
                        data={channel}
                        name={"Channel"}
                        textField="FullChannelName"
                        dataItemKey="_id"
                        value={dataItem.Channel}
                        onChange={onChange}
                    />
                </div>
                <div className='col-2' style={{ textAlign: "left", marginTop: "20px", padding: 0 }}>
                    <ActionButton title={lang.show_button_tooltip} name={lang.show_button_text} onClick={loadHistory} />
                </div>
            </div>

            {selectedItems.length > 0 && <div className="row">
                <div className="col-6" >
                    <div className="form-group">
                        <label htmlFor="">Replace With : </label>
                        <input
                            name={"Media"}
                            className="form-control form-control-sm"
                            type={'text'}
                            value={selectedMedia.Title}
                            disabled={true}
                            onChange={() => ""}
                        />
                    </div>
                </div>
                <button type="button" className="ml-2 k-button k-button-md k-rounded-md k-button-solid k-button-solid-success"
                    style={{ height: "30px", marginTop: "20px" }} title={lang.attach_media_button_tooltip} onClick={(e) => { setMediaOpen(true); e.preventDefault(); }}>
                    <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                </button>
                <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-info"
                    style={{ height: "30px", marginTop: "20px", marginLeft: '4px' }} title={lang.delete_button_tooltip} onClick={(e) => { deleteSelectedMedia(); e.preventDefault() }}>
                    <i className="fa-solid fa-trash"></i>
                </button>
                <div className='col-3' style={{ textAlign: "left", marginTop: "20px", padding: 0 }}>
                    <ActionButton title={lang.replace_button_tooltip} name={lang.replace_button_text} onClick={onReplace} />
                </div>

            </div>}

            <Grid
                style={{ height: "50vh", marginTop: "04px", overflow: "hidden" }}
                data={gridData.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                }}
                dataItemKey={DATA_ITEM_KEY}
                onSelectionChange={onSelectionChange}
                resizable={true}
                onHeaderSelectionChange={onHeaderSelectionChange}
            >
                <GridNoRecords>{loadingStatus}</GridNoRecords>
                <Column
                    field={SELECTED_FIELD}
                    width="50px"
                    headerSelectionValue={
                        gridData.findIndex(
                            (item) => !selectedState[idGetter(item)]
                        ) === -1
                    }
                    locked={true}
                    filterable={false}
                />
                <Column field="Title" title={lang.title_column} width={188} />
                <Column field="AssetId" title={lang.assetId_column} width={188} />
                <Column field="ChannelName" title={lang.channel_column} width={188} />
                <Column field="Date" title={lang.slot_date_column} cell={DateOnlyCell} width={180} />
                <Column field="Date" title={lang.slot_time_column} cell={TimeCell} width={180} />
                <Column field="Duration" title={lang.slot_time_column} cell={TimeCell} width={180} />
            </Grid>
            <div className="text-right mt-1">{`Total : ${gridData.length}`}</div>

            {mediaOpen && <ContractCollectionSelection addButtonTitle={lang.select_button_text} title={lang.media_library_dialog_header} wherestatement={['MediaCategory.isLong', '=', false]} setDataList={handleMediaSelect} entityname={ENTITYNAME.MediaEpisode} isLongMediaCategory={false} closeForm={() => setMediaOpen(false)} mediaCategory={selectedData.MediaCategory} mode={'single'} width={"50vw"} height={"66vh"} />}

        </BossDialog>
    )
});

export default ShortFormMediaEpisodeHistory;
