/* eslint-disable */
import { Input } from '@progress/kendo-react-inputs';
import React, { useEffect, useRef, useState } from 'react';
import { EditPageHeader } from '../../components/EditPageHeader';
import { toast } from 'react-toastify';
import * as API from "../../framework/API/api_digitalSign";
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { COMPANYTYPE, LOCALSTORAGE_KEY, MODULE, SOCKET_ACTION } from '../../framework/constant/constant';
import qrImage from '../../img/qrcode.png';
import BossDialog from '../../components/BossDialog';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const ActivateScreenPoup = ({ onClose, lastItem, selectedPlan, isMobile = false, isTransferScreen = false, screenData = {}, isShow }) => {

    const lang = useTranslation();
    const [otp, setOtp] = useState(['', '', '', '']);
    const [name, setName] = useState(isTransferScreen ? screenData?.Name : `Screen ${lastItem + 1}`);
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];
    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
    const companies = utility.getValue(LOCALSTORAGE_KEY.COMPANIES);
    const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);
    const [selectedCompany, setSelectedCompany] = useState(companies?.length > 0 ? companies[0] : {});
    const [showUserGuide, setShowUserGuide] = useState(false);

    // Add combobox companies if enterprise login

    useEffect(() => {
        inputRefs[0].current.focus();
    }, []);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Move to next input if current field is filled
        if (element.value.length === 1) {
            if (index < 3) {
                inputRefs[index + 1].current.focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            e.preventDefault();
            if (otp[index]) {
                const newOtp = [...otp];
                newOtp[index] = '';
                setOtp(newOtp);
            } else if (index > 0) {
                inputRefs[index - 1].current.focus();
            }
        } else if (e.key === "Enter") {
            onSubmit();
        }
    };

    const handleFocus = (index) => {
        inputRefs[index].current.select();
    };

    const isValid = () => {
        if (name.length == 0) {
            toast.info(lang.please_enter_screen_name_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        if (isEnterpriseLogin && (!selectedCompany || Object.keys(selectedCompany).length == 0)) {
            toast.info(lang.please_select_company_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        if (otp[0] == '' || otp[1] == '' || otp[2] == '' || otp[3] == '') {
            toast.info(lang.please_enter_otp_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        return true;
    }

    const handleTransferScreen = async () => {
        const finalCompanyId = isEnterpriseLogin ? selectedCompany._id : company?._id;
        let finalData = {
            otp: otp.join(''),
            company_id: finalCompanyId,
            screen_id: screenData?._id
        }
        let res = await API.transferScreen(finalData);
        if (res.success) {
            await API.sendEvent(screenData?.socketID, { action: SOCKET_ACTION.RESTART_APP, module: MODULE.ALL, data: null });
            await API.sendEvent(res?.socketID, { action: SOCKET_ACTION.RESTART_APP, module: MODULE.ALL, data: null });
            toast.success(res.message);
        } else {
            toast.error(res.message);
        }
        onClose(true);
    }

    const onSubmit = async () => {

        if (!isValid()) return;

        if (isTransferScreen) {
            await handleTransferScreen();
            return;
        }

        const enteredOtp = otp.join('');
        const finalCompanyId = isEnterpriseLogin ? selectedCompany._id : company?._id;
        let selectedPlanId = selectedPlan?._id;
        if (isEnterpriseLogin) {
            let canActivateScreenRes = await API.canActivateScreen(finalCompanyId);
            if (!canActivateScreenRes.success) {
                toast.error(canActivateScreenRes.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
                return;
            } else {
                selectedPlanId = canActivateScreenRes?.data?._id;
            }
        }
        let res = await API.activateScreen(enteredOtp, name, selectedPlanId, finalCompanyId);

        if (!res.success) {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        } else {
            if (res.data.isValid) {
                if (res.data.isActive) {
                    let screenSocketID = res.data.socketID;
                    let screenGroupId = res.data.screenGroupId;
                    await API.joinCompany(screenSocketID, finalCompanyId, screenGroupId);
                    await API.sendEvent(screenSocketID, { action: SOCKET_ACTION.DIGI_PLAY_PLAYLIST, module: MODULE.ALL, data: null });
                }
                setShowUserGuide(true);
                // onClose(true)
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }

    const onDownloadApp = () => {
        window.open("https://automateb.com/digital-signage-download/#links", "_blank")
    }

    const buttonStyle = {
        color: '#2342C0',
        cursor: 'pointer'
    }

    return (
        <BossDialog
            title={lang.activate_screen_dialog_header}
            onClose={() => onClose(false, showUserGuide ? true : false)}
            width={isMobile ? "95%" : showUserGuide ? "600px" : "500px"}
        >
            {showUserGuide ?
                <div style={{ marginTop: '10px' }}>
                    <div style={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }} className='text-success'>
                        Congratulations 🎉 <br />
                        <p className="mt-1" style={{ fontSize: '1.1rem' }}>
                            Your screen has been activated.
                        </p>
                    </div>
                    <h6 className='ml-1' style={{ fontWeight: 'bold' }}>Let's take 1 minute to finish optimizing your display</h6>
                    <ul>
                        <li className='pl-1'>
                            <strong>Enable the auto-start permission</strong>: <br /> Go to your player settings and allow automateB to start automatically on boot.
                        </li>
                        <li className='mt-2 pl-1'>
                            <strong>Grant permission to display over other apps</strong>: <br /> Ensure automateB is enabled in the "Display Over Other Apps" settings.
                        </li>
                        <img src={require("../../img/user-guide.png")} style={{ width: '100%', height: 'auto', marginTop: '20px' }} />
                    </ul>
                </div> : <>
                    <EditPageHeader showSaveButton={!isMobile} saveBtnColor={'success'} saveButtonTooltip={lang.activate_button_tooltip} saveButtonTitle={lang.activate_button_text} showTitle={false} onSubmit={onSubmit} onCancel={onClose} />
                    <div className='mt-2'>
                        <div className='mb-2'>
                            <label>{lang.name_label} *</label>
                            <Input name='screenName' style={{ border: '1px solid #CCCCCC' }} className='form form-control' type="text" placeholder='Enter Screen Name' value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        {isEnterpriseLogin && <div className="mb-4">
                            <label htmlFor="TabView">{lang.company_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={companies}
                                name="Company"
                                textField="Name"
                                dataItemKey="_id"
                                value={selectedCompany}
                                onChange={(e) => setSelectedCompany(e.target.value)}
                            />
                        </div>}
                        <label className='d-flex justify-content-center align-items-center text-center mb-2'>{lang.enter_your_pin_label}</label>
                        <div className='d-flex justify-content-center align-items-center text-center'>
                            {otp.map((data, index) => {
                                return (
                                    <input
                                        className="otp-input mr-3 screen-pin"
                                        key={index}
                                        type="number"
                                        maxLength="1"
                                        ref={inputRefs[index]}
                                        value={data}
                                        onChange={e => handleChange(e.target, index)}
                                        onKeyDown={e => handleKeyDown(e, index)}
                                        onFocus={() => handleFocus(index)}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    {isMobile && 
                    <div className="d-flex justify-content-center mt-4 rounded">
                        <button
                            className="btn btn-success w-100 text-center"
                            onClick={onSubmit}
                        >
                            {lang.activate_button_text}
                        </button>
                    </div>}
                    <div className={!isMobile && 'mt-3'}>
                        <div className='row d-flex justify-content-center align-items-center'>
                            <div className='col text-center' style={{ marginTop: isMobile && "20px" }}>
                                <div className='border rounded p-3' style={{ height: '140px' }}>
                                    <img width="80px" src={qrImage} alt='qr-code' />
                                    <i style={buttonStyle} className='fa fa-download mt-2' onClick={onDownloadApp}><span className='ml-1'>Download the App</span></i>
                                </div>
                                <p className='mt-2'>
                                    Go to <a style={buttonStyle} onClick={onDownloadApp}>your player's</a> app store and download our app. Find your particular player's setup guide <a style={buttonStyle} onClick={onDownloadApp}>here.</a>
                                </p>
                            </div>
                            <div className='col-1' style={isMobile ? { margin: "-33% 10px 0px -16px" } : { marginLeft: '-10px', marginTop: '-15%' }}>
                                <i className='fa fa-arrow-right rounded rounded-square p-2' style={{ color: 'white', backgroundColor: '#2342C0' }}></i>
                            </div>
                            <div className='col text-center'>
                                <div className='border rounded p-3' style={{ height: '140px' }}>
                                    <label className={isMobile ? 'p-1 mt-2' : 'p-1 mt-4'} style={{ fontWeight: 600, letterSpacing: 1, color: '#2342C0', border: '2px solid #2342C0' }}>1234</label>
                                    <label className='mt-2'>Enter displayed PIN above</label>
                                </div>
                                <p className='mt-2'>
                                    When you launch the app you'll be prompted with a PIN. Enter the Pin above
                                </p>
                            </div>
                        </div>
                    </div></>}
        </BossDialog >
    );
}

export default ActivateScreenPoup;
