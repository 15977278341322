import AWS from 'aws-sdk';

const uploadFileS3 = async (file, credential={}, fileUploadProgress, compnay) => {
    
    if (!file) {
        return { success: false, message: 'Please select a file' };
    }

    // Configure AWS SDK
    AWS.config.update({
        accessKeyId: credential?.accessKeyId,
        secretAccessKey: credential?.secretAccessKey,
        region: credential?.region
    });

    const isImage = file.type.startsWith('image/');
    const isVideo = file.type.startsWith('video/');
    let bucketName, cdnUrl;

    if (isImage) {
        bucketName = credential?.imageBucketName;
        cdnUrl = credential?.imageCdnUrl;
    } else if (isVideo) {
        bucketName = credential?.videoBucketName;
        cdnUrl = credential?.videoCdnUrl;
    } else {
        return { success: false, message: 'Unsupported file type. Only images and videos are allowed.' };
    }

    const s3 = new AWS.S3();
    const companyName = compnay?.Name.replace(/\s+/g, '') + '_' + compnay?._id;
    let fileName = 'bossuateb2dd92b/' + companyName + '/' + file.name;
    const params = {
        Bucket: bucketName,
        Key: fileName,
        Body: file,
        ContentType: file.type,
        ACL: 'bucket-owner-full-control'
    };

    try {
        const upload = s3.upload(params);

        // Listen for progress updates
        upload.on('httpUploadProgress', (progress) => {
            const percentage = Math.round((progress.loaded / progress.total) * 100);
            fileUploadProgress(percentage);
        });
        const result = await upload.promise();
        console.log(result);
        const cloudFrontUrl = cdnUrl + fileName;
        console.log(cloudFrontUrl);
        let thumbnailFileName = isVideo ? ((cloudFrontUrl?.split('.')?.slice(0, -1)?.join('.') ?? cloudFrontUrl) + '_thumbnail.jpeg') : cloudFrontUrl;
        let finalResponse = {
            asset_id: fileName, 
            public_id: fileName,
            format: isImage ? 'png' : 'mp4',
            thumbnailUrl: thumbnailFileName,
            resource_type: isImage ? 'image' : 'video',
            url: cloudFrontUrl,
            secure_url: cloudFrontUrl,
            playback_url: isVideo ? cloudFrontUrl : null
        }
        
        return { success: true, message: 'File uploaded successfully', data: finalResponse};
    } catch (error) {
        return { success: false, message: error.message };
    }
}

export { uploadFileS3 };