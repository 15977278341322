/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { utility } from "../../../framework/utility/utilityProvider";
import { CardContent, CardMedia } from "@mui/material";
import { ContextMenu } from "primereact/contextmenu";
import { COMPANYTYPE, CONTENT_TYPE, LOCALSTORAGE_KEY, ORIENTATION } from "../../../framework/constant/constant";

const LiveEventCard = (props) => {

  const { item, cardInline, index, tooltip, title, description, subDescription, subDescription2, showLive, showResolutionCard = false, showIcon = false, showCompanyName = false } = props;
  let showLiveIcon = showLive ? showLive : false;
  let inline = cardInline ? cardInline : 3;

  const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
  const companies = utility.getValue(LOCALSTORAGE_KEY.COMPANIES);
  const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);

  let cardTooltip = tooltip ? tooltip : `${item.MediaEpisode?.Title +
    "\n\n" +
    item.MediaEpisode?.Description +
    "\n\n" +
    item?.TeamA +
    "\n" +
    "vs " +
    "\n" +
    item?.TeamB +
    "\n" +
    utility.convertMilisecondsToStringWithFrames(
      item.MediaEpisode?.Duration
    ) +
    "\n\n"}`;

  let cardTitle = title ? title : item.MediaEpisode?.Title;
  let cardDescription = description ? description : `${item?.TeamA + "\n" + "V/s " + "\n" + item?.TeamB}`;
  let cardSubDescription = subDescription ? subDescription : `${utility.convertMilisecondsToDateTimeString(item?.StartDateTime)}
  | ${utility.convertMilisecondsToStringWithFrames(item.MediaEpisode?.Duration)}`;
  let cardSubDescription2 = subDescription2 ? subDescription2 : ` ${item?.Venue}`;

  return (
    <div className={`col-sm-12 col-md-6 col-lg-${inline}`}>
      <div
        style={{
          cursor: "pointer",
          margin: "10px",
          height: "250px",
          minWidth: "180px",
          maxWidth: "300px",
          backgroundColor: "white",
          border: "none",
        }}
        onContextMenu={() => { }}
        onClick={() => { }}
        className="card mb-3"
        key={index}
        // for card tooltip
        title={cardTooltip}
      >
        <card>
          <CardMedia>
            <img src={item?.PosterUrl} className="card-img-top" alt={item?.Name}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = 'https://media.comicbook.com/files/img/default-movie.png';
              }}
              style={{
                height: "150px",
                objectFit: "cover",
                paddingBottom: "5px",
                position: "absolute",
              }}
            />
          </CardMedia>
          {showIcon && <div style={{
            position: "absolute",
            top: 0,
            fontSize: "15px",
            transform: "translateX(0.2%)",
            backgroundColor: "black",
            color: "white",
            borderRadius: '5px',
            opacity: 0.7,
            margin: '5px',
          }}>
            <i className={`fa fa-${item?.content_type == CONTENT_TYPE.GoogleSlides ? 'square' : item?.content_type == CONTENT_TYPE.Stream ? 'stream' : item?.content_type == CONTENT_TYPE.Image ? 'picture-o' : item?.content_type == CONTENT_TYPE.Video ? 'video-camera' : 'youtube'} m-2`} />
          </div>
          }
          {showCompanyName && isEnterpriseLogin && <div style={{
            position: "absolute",
            top: 0,
            right: 0,
            fontSize: "15px",
            transform: "translateX(0.2%)",
            color: "#8a8888",
            borderRadius: '5px',
            opacity: 0.7,
            margin: '5px',
          }}>
            <div className="pull-right" style={{ display: "flex", color: "white", justifyContent: "space-between", padding: "0 06px", fontSize: "12px" }}> {companies?.length > 0 ? utility.subString(companies.find((c) => c?._id === item?.Company_id).Name, 14) : ''}</div>
          </div>
          }
          {showLiveIcon && <div
            style={{
              position: "absolute",
              color: "white",
              top: 0,
              fontSize: "15px",
              right: "0%",
              backgroundColor: "#048ad4",
              transform: "translateX(0.2%)",
            }}
          >
            &nbsp;{" "}
            {showLiveIcon && item?.Type?.Description == "Live" && (
              <i
                className="fa-solid fa-circle  fa-2xs"
                style={{ color: "red" }}
              />
            )}{" "}
            {showLiveIcon ? item?.Type?.Description : ""} &nbsp;
          </div>}
        </card>

        <div
          style={{
            display: "grid",
            alignContent: "space-around",
            height: "100%",
            marginTop: "10vh",
          }}
        >
          <div
            className="card-subtitle mt-1 text-muted"
            style={{ fontSize: "small" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", padding: "54px 0px 13px 13px", fontSize: "15px", fontWeight: "500", color: "black" }}>
              {utility.subString(cardTitle, 11)}
              {showResolutionCard && <span style={{ marginTop: "4px", marginRight: "10px", fontSize: "12px", color: "#8a8888" }}>{utility.subString(item?.Resolution?.Description, 10)}</span>}

            </div>
            {item?.content_type !== CONTENT_TYPE.Stream && (
              <div style={{ display: "flex", justifyContent: "space-between", padding: "0 13px" }}>
                {utility.subString(cardDescription, 19)}
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "space-between", padding: "0 13px", fontSize: "10px" }}>
              {utility.subString(cardSubDescription, 24)}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "0 13px", fontSize: "10px" }}>
              {utility.subString(cardSubDescription2, 24)}
            </div>
          </div>

          <div
            style={{ position: "absolute", bottom: "5px", right: "10px", padding: "0 7px", borderRadius: "5px", cursor: "pointer" }}
            onClick={(e) => props.onContextMenu(e, item)}
          >
            <i className="fa-solid fa-ellipsis" />
          </div>
        </div>
      </div>

    </div>
  );
};

export default LiveEventCard;
