import React from 'react'

const IconSquareButton = (props) => {

    let enableInput = props.inputField ?? false;
    let onFileChange = props.onFileChange ?? (() => { });
    let onClick = props.onClick ?? (() => { });

    return (
        <div>
            <button
                className="m-3"
                style={{
                    width: props.width ?? '150px',
                    height: props.height ?? '150px',
                    borderRadius: props.borderRadius ?? '25px',
                    padding: '0px', margin: '0px 0px 0px 2px',
                    backgroundColor: props.backgroundColor ?? "grey",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    border: "none"
                }}
                onClick={() => { onClick() }}
                title={props.title}
            >
                {enableInput && <input
                    name={"file"}
                    className='custom-file-updated-input form-control form-control-sm'
                    style={{ position: "absolute", height: "100%", width: "100%", cursor: "pointer", zIndex: 999999, backgroundColor: "transparent", opacity: 0 }}
                    type={props.buttonType ?? "file"}
                    accept={props?.accept ?? "image/*,video/*"}
                    multiple onChange={(e) => { onFileChange(e) }} />}
                {
                    props?.name == "Google Slides" && <div style={{ position: "absolute", top: 0, right: 0, backgroundColor: "#e79d18", height: "25px", width: "25px" }}></div>
                }
                {
                    props?.name == "Google Slides" && <div style={{ position: "absolute", top: "-13px", right: "-18px", backgroundColor: "white", height: "25px", width: "50px", transform: "rotate(45deg)" }}></div>
                }
                {props?.name == "Google Slides" ?
                    <div style={{ border: "4px solid white", height: "30px", width: "45px", borderRadius: "2px", position: "relative", top: "5px" }}>

                    </div> : <label className={`fa ${props.icon ?? 'fa-image'}`} style={{ color: props.iconColor ?? 'white', fontSize: props.iconSize ?? '75px', padding: '7px 9px', margin: '0px', cursor: 'pointer' }}>
                    </label>
                }
            </button>
            <div className='text-center'>{props?.name}</div>
        </div>
    )
}

export default IconSquareButton