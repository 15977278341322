import React, { useEffect, useState } from 'react'
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { EditPageHeader } from '../../components/EditPageHeader';
import BossDialog from '../../components/BossDialog';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const CastTypeEditForm = (props) => {

    const lang = useTranslation();
    const blankDataItem = {
        ...props.item,
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        Archive: props.item.Archive ?? false,
        Language: props?.item?.Language ?? {}
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        loadCombo()
    }, [])

    const loadCombo = async () => {
        let languagesRes = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
        setLanguages(languagesRes.data);

        if(props?.item?.SID == 0){
            setDataItem({...dataItem , Language: utility.getDefaultItem(languagesRes.data)})
        }else{
            setDataItem({ ...dataItem, Language: props?.item?.Language && Object.keys(props?.item?.Language)?.length > 0 ? props?.item?.Language : utility.getDefaultItem(languagesRes.data) });
        }
    }

    const onChange = (e) => {
        if (e.target.name === 'Archive') {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {

        if (dataItem.Description == undefined || dataItem.Description == "") {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const handleSubmit = async () => {
        if (!isValid()) return;
        const saveData = {
            _id: dataItem._id,
            SID: dataItem.SID,
            Description: dataItem.Description,
            Language: dataItem?.Language && Object.keys(dataItem?.Language)?.length > 0 ? {
                _id: dataItem?.Language?._id,
                ISOCode: dataItem?.Language?.ISOCode,
                Description: dataItem?.Language?.Description
            } : {},
            Archive: dataItem.Archive ?? false,
            checkDuplicate: true,
            query: [["Description", "=", dataItem.Description], ["Language._id", "=", dataItem?.Language?._id]]
        }
        const res = await API.saveData(ENTITYNAME.CastType, saveData);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.CASTTYPE, data: res.data, message: res.message };
            API.SaveLogs(logData);
            props.cancelEdit();
            props.refresh();
            utility.deleteLocalStorageItem(ENTITYNAME.CastType);
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (
        <>
            <BossDialog
                title={props.item.Description ?? lang.cast_Type_dialog_header}
                onClose={props.cancelEdit}
                width={"600px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />

                <div className='row mt-2'>
                    <div className='col-12'>
                        <div className='row mt-2'>
                            <div className='col-6'>
                                <label>{lang.description_label} *</label>
                                <input
                                    name={"Description"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Description}
                                />
                            </div>
                            <div className="col-6">
                                <label>{lang.language_label}:</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={languages}
                                    name="Language"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.Language}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-6'>
                                <input
                                    style={{ border: "1px solid grey" }}
                                    name={"Archive"}
                                    type='checkbox'
                                    onChange={onChange}
                                />
                                <label className="ml-2">{lang.archive}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog >
        </>
    )
}

export default CastTypeEditForm
